import axios from "axios";

export const AuthenticateApp = () => {
  return new Promise(resolve => {
    axios
      .get("/api/authenticate")
      .then(({ data }) => {
        localStorage.setItem("token", data.token);

        resolve({ status: "authenticated" });
      })
      .catch(error => {
        console.error(error);

        resolve({ status: "unauthenticated" });
      });
  });
};

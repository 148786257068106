import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Async from "../../services/async";
import config from "./config";
import arrayMove from 'array-move';
import { Question, ComponentSelectModal } from "./UIComponents";
import {
  Container, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Button,
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText, Row, Col, ButtonGroup, ButtonToolbar
} from 'reactstrap';
import classnames from 'classnames';

export default (props) => {
  let history  = useHistory();
  let { surveyUuid } = useParams();

  const [questions, setQuestions] = useState([]);
  const [pages,  setPages]  = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const isFirstRun = useRef(true);

  useEffect(() => {
    if(isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, [questions])

  useEffect(() => {
    if(surveyUuid) {
      Async.get(`journey/${surveyUuid}/questions`)
      .then(data => {
        data.survey.questions.map((q, i) => {
          if(!q.order) q.order = i
        })

        data.survey.questions.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        if(data.survey.divide_after) {
          let lastDivide = 0;

          let obj = {}

          data.survey.divide_after.split(',').map(divide => {
            obj[divide] = []

            data.survey.questions.map(question => {
              if(question.order >= lastDivide) {
                if(question.order < divide) {
                  obj[divide].push(question)
                }
                else {
                  lastDivide = divide
                }
              }
            })
          })
          setPages(obj)
        }

        setQuestions(data.survey.questions);
      })
    }
  }, []);

  const QuestionPages = () => (
    <div className="mt-5 pt-5">
      <TabContent activeTab={`${activeTab}`}>
        {Object.keys(pages).map((page, i) => (
          <TabPane key={i} tabId={`${i}`}>
            <ListGroup>
              {pages[page].map((question, index) => (
                <Question
                  key={question.uuid || index}
                  question={question}
                />
              ))}
            </ListGroup>
          </TabPane>
        ))}
      </TabContent>
      {/*<hr/>*/}
      <div className="text-center mt-4">
        <ButtonToolbar>
          <ButtonGroup>
            <Button onClick={() => toggle(activeTab - 1)}>{'< Prev'}</Button>
          </ButtonGroup>
          <ButtonGroup className="mx-2">
            {Object.keys(pages).map((page, i) => (
              <Button key={i}
                onClick={() => toggle(i)}
                className={classnames({ active: activeTab === i })}
                >
                {i + 1}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup>
            <Button onClick={() => toggle(activeTab + 1)}>{'Next >'}</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>
  )

  return questions ? (
    <Container className="pb-5">
      <QuestionPages />
    </Container>
  ) : (
    <div className="text-center">Loading...</div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Async from "../../services/async";
import config from "./config";
import arrayMove from 'array-move';
import { Question, ComponentSelectModal } from "./UIComponents";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Container, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Button } from 'reactstrap';


export default (props) => {
  let history  = useHistory();
  let { surveyUuid } = useParams();
  // surveyUuid is temporarily journeyUuid - 1:1

  const [questions, setQuestions] = useState([]);
  const [dividers,  setDividers]  = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const isFirstRun = useRef(true);

  // useEffect(() => {
  //
  //   setModalOpen(false)
  //
  // }, [questions])

  useEffect(() => {
    if(isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    let questionDividers = questions.filter(q => q.divider === true).map(d => {
      return d.order
    })
    let str = questionDividers.join(',')

    setDividers(str)

    if(str) Async.put(`journey/${surveyUuid}`, { divide_after: str })

    Async.post(`questions`, { questions })

  }, [questions])

  useEffect(() => {
    if(surveyUuid) {
      Async.get(`journey/${surveyUuid}/questions`)
      .then(data => {
        data.survey.questions.map((q, i) => {
          if(!q.order) q.order = i
        })
        data.survey.questions.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));

        if(data.survey.divide_after) {
          setDividers(data.survey.divide_after)
          let dividerArray = data.survey.divide_after.split(',')

          dividerArray.map(position => {
            data.survey.questions.splice(parseInt(position), 0, { divider: true })
          })
        }
        // clean up orders after inserting dividers
        data.survey.questions.map((q, i) => { q.order = i })

        setQuestions(data.survey.questions);
      })
    }
  }, []);

  const editQuestion = (uuid, obj) => {
    setQuestions(questions.map(question => {
      return ( question.uuid === uuid ? { ...question, ...obj } : question )
    }));
    Async.put(`question/${uuid}`, { question: obj })
  }

  const removeQuestion = (uuid, divider = null) => {
    if(uuid) {
      setQuestions(questions.filter(question => question.uuid !== uuid))

      Async.delete(`question/${uuid}`)
    }
    else if(divider) {
      setQuestions(questions.filter(question => question.order !== divider))

      let arr = dividers.split(',')
      let idx = arr.findIndex(d => d === divider.toString())
      arr.splice(idx, 1)
      let str = arr.join(',')

      Async.put(`journey/${surveyUuid}`, { divide_after: str })
    }
  };

  const addDivider = () => {
    let ordered = [{ divider: true }, ...questions].map((q, i) => {
      q.order = i
      return q
    })
    setQuestions(ordered)
  }

  const createQuestions = notes => {
    Async.post(`journey/${surveyUuid}`, { notes })
    .then(data => {

      let ordered = [...questions, ...data.questions]
      .map((q, i) => {
        q.answer_type = q.answer_type || config.answerTypes[0].uuid
        q.order = i
        return q
      });

      setQuestions(ordered)
    })
    .catch(error => console.error(error))
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let sorted = arrayMove(questions, oldIndex, newIndex)

    sorted.map((question, index) => question.order = index)

    setQuestions(sorted);
  };

  const SurveyHeaderBar = () => (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="m-0">Surveys</h3>
      <div>
        <Button outline color="secondary" size="sm" onClick={() => addDivider()}>
          Add Divider
        </Button>
        <Button className="bg-dark border-dark ml-2" size="sm" onClick={() => setModalOpen(true)}>
          Add Question
        </Button>
        <ComponentSelectModal
          nodeLabel="Note"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          headerText="Select Notes to Create Questiond from"
          submitText="Create Questions"
          handleSubmit={createQuestions}
        />
      </div>
    </div>
  )

  const SortableItem = SortableElement(({ question }) => {
    return <Question question={question} editQuestion={editQuestion} removeQuestion={removeQuestion} />
  });

  const SortableList = SortableContainer(({ questions }) => {
    return (
      <ListGroup>
        {questions.map((question, index) => (
          <SortableItem key={question.uuid || index} index={index} question={question} />
        ))}
      </ListGroup>
    );
  });

  return questions ? (
    <Container>
      <SurveyHeaderBar />
      <hr />
      <SortableList questions={questions} distance={2} onSortEnd={onSortEnd} useSortHandle />
    </Container>
  ) : (
    <div className="text-center">Loading...</div>
  );
}

import React, { useState } from "react";
import { Button } from "reactstrap";
import { ComponentSelectModal } from "../";

export default () => {
  const [modalOpen, setModalOpen] = useState(false);

  return(
    <div>
      <Button onClick={() => setModalOpen(true)} className="bg-dark border-dark" block size="md">
        Select Options
      </Button>
      <ComponentSelectModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
}

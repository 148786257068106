import ReactDOM from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faSave,
  faEdit,
  faTimesCircle,
  faGripLines
} from "@fortawesome/free-solid-svg-icons";

library.add(faTrash, faSave, faEdit, faTimesCircle, faGripLines);

import React, { useState } from 'react';
import { Link, NavLink as NavLinkRRD, useParams } from 'react-router-dom';
import routes from '../../routes';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';

const SurveyNav = (props) => {
  let params = useParams();

  console.log(params)

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      {window.location.pathname.includes('takesurvey') ? '' : (
        <Navbar color="dark" dark expand="md" id="topnav">
          <Container>
          <NavbarBrand tag={Link} to={'/'}>
            Survey Manager
          </NavbarBrand>

          <NavbarToggler onClick={toggle} />

          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {routes.map((prop, key) => {
                if(false && prop.path.length > 1) {
                  return (
                    <NavItem key={key}>
                      <NavLink
                        to={prop.path}
                        tag={NavLinkRRD}
                        onClick={toggle}>
                        {/*<i className={prop.icon} />*/}
                        {prop.name}
                      </NavLink>
                    </NavItem>
                  )
                }
              })}
            </Nav>
            <Nav>
              <NavItem>
                <NavLink to={`/takesurvey`} tag={NavLinkRRD}>
                  View Surveey
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          </Container>
        </Navbar>
      )}
    </div>
  );
}

export default SurveyNav;

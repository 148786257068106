import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Async from "../../services/async";
import config from "./config";
import arrayMove from 'array-move';
import { Question, ComponentSelectModal } from "./UIComponents";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Container, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Button } from 'reactstrap';


export default (props) => {
  let history  = useHistory();
  let { surveyUuid } = useParams();

  const [questions, setQuestions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if(surveyUuid) {
      Async.get(`survey/${surveyUuid}`)
      .then(data => {
        data.questions.map((q, i) => {
          q.order = i
          if(!q.answer_type) q.answer_type = config.answerTypes[0].uuid
        })
        setQuestions(data.questions);
      })
    }
  }, []);

  const editQuestion = (uuid, obj) => {
    setQuestions(questions.map(question => {
      return ( question.uuid === uuid ? { ...question, ...obj } : question )
    }));
  }

  const removeQuestion = uuid => setQuestions(questions.filter(question => question.uuid !== uuid));

  const addNewQuestion = () => {
    setQuestions([
      ...questions,
      {}
    ]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let sorted = arrayMove(questions, oldIndex, newIndex)

    sorted.map((question, index) => question.order = index)

    setQuestions(sorted);
  };

  const SurveyHeaderBar = () => (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="m-0">Surveys</h3>
      <div>
        <Button className="bg-dark border-dark" block size="sm">
          Add Questions
        </Button>
        <ComponentSelectModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  )

  const SortableItem = SortableElement(({ question }) => (
    <Question question={question} editQuestion={editQuestion} removeQuestion={removeQuestion} />
  ));

  const SortableList = SortableContainer(({ questions }) => {
    return (
      <ListGroup>
        {questions.map((question, index) => (
          <SortableItem key={question.uuid} index={index} question={question} />
        ))}
      </ListGroup>
    );
  });

  return questions ? (
    <Container>
      <SurveyHeaderBar />
      <hr />
      <SortableList questions={questions} onSortEnd={onSortEnd} useSortHandle />;
    </Container>
  ) : (
    <div className="text-center">Loading...</div>
  );
}

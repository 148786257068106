import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { Button, FormGroup, Input, Modal, Container, Row, Col, Card, CardBody, CardText } from "reactstrap";
import axios from "axios";

export default ({ modalOpen, setModalOpen }) => {
  let { surveyUuid } = useParams();

  const [notes, setNotes] = useState([]);
  const [selected, setSelected] = useState([]);

  if(modalOpen && surveyUuid) {
    axios
    .get(`/api/survey/${surveyUuid}/notes`)
    .then(({ data }) => {
      setNotes(data)
    });
  }

  const selectNote = () => {
    console.log('select')
  }

  const Options = () => {
    return (
      <Row>
        {notes.map((note, index) => (
          <Col key={note.uuid} xs="3">
            <Card className="note" style={{height: '10rem', cursor: 'pointer'}} onClick = {() => selectNote(note.uuid)}>
              <CardBody>
                <CardText>{note.description}</CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} className="modal-lg note-select-modal">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLiveLabel">
          Choose Options for Select Answer Type
        </h5>
      </div>
      <div className="modal-body">
        <Container fluid={true}>
          <Options />
        </Container>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className="btn-link"
            color="danger"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Save Selections
          </Button>
        </div>
      </div>
    </Modal>
  )
}

import React, { useState } from "react";
import config from "../config";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Input, Badge, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default ({question, editQuestion, removeQuestion}) => {
  const [editMode,     setEditMode]     = useState(false);
  const [questionText, setQuestionText] = useState(question.text)
  const [answerType,   setAnswerType]   = useState((question.answer_type || config.answerTypes[0].uuid))

  const confirmDelete = (uuid, dividerPosition) => {
    let c = window.confirm('Are you sure you want to remove this question from the survey?')

    if(c) {
      dividerPosition ? removeQuestion(null, dividerPosition) : removeQuestion(uuid)
    }
  }

  const saveChanges = () => {
    let changes = {
      text: questionText,
      answer_type: answerType
    }
    if(answerType === 'radioscale') {
      changes.min = 0;
      changes.max = 5
    }
    editQuestion(question.uuid, changes)
  }

  return (
    <>
      {question.divider ? (
        <Row className="question-divider">
          <Col>
            <Button size="sm" className="bg-dark border-dark move">
              <FontAwesomeIcon
                icon="grip-lines"
                size="sm"
                className="text-light"
              />
            </Button>
          </Col>
          <Col className="text-center pointer">
            <h5>Page Divider</h5>
          </Col>
          <Col className="text-right">
            <Button size="sm" className="bg-dark border-dark delete"
              onClick={() => confirmDelete(null, question.order)}>
              <FontAwesomeIcon
                icon="trash"
                size="sm"
                className="text-light"
              />
            </Button>
          </Col>
        </Row>
      ):(
        <ListGroupItem className="d-flex">
          {editMode ? (
            <Input type="textarea"
              name="text"
              className="question-textarea"
              defaultValue={question.text}
              onChange={(e) => setQuestionText(e.target.value)}
              />
          ):(
            <div className="mr-auto">
              <ListGroupItemHeading onClick={() => setEditMode(true)}>
                {questionText}
              </ListGroupItemHeading>
              {/*<ListGroupItemText className={question.done ? "done" : ""}>
                <Badge color="dark" className="mr-2">order: {question.order}</Badge>
              </ListGroupItemText>*/}
            </div>
          )}
          <div className="answer-types">
            <select onChange={({ target }) => setAnswerType(target.value)} className="form-control answer-type-select mb-3" value={answerType}>
              {config.answerTypes.map(answerType => {
                return (
                  <option key={answerType.uuid} value={answerType.uuid}>
                    {answerType.label}
                  </option>
                )
              })}
            </select>
            {config.answerTypes.find(a => a.uuid === answerType).component}
          </div>
          <div className="question-actions">
            <Row className="flex-column justify-content-between">
              <Col>
                <FontAwesomeIcon
                  icon="save"
                  size="lg"
                  className="text-success"
                  disabled={!editMode}
                  onClick={saveChanges}
                />
              </Col>
              <Col>
                <FontAwesomeIcon
                  icon="trash"
                  size="lg"
                  className="text-danger"
                  onClick={() => confirmDelete(question.uuid)}
                />
              </Col>
              {editMode && (
                <Col>
                  <FontAwesomeIcon
                    icon="times-circle"
                    size="lg"
                    className="text-secondary"
                    onClick={() => { setQuestionText(question.text); setEditMode(false) }}
                  />
                </Col>
              )}
            </Row>
          </div>
        </ListGroupItem>
      )}
    </>
  )
}

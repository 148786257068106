import React from "react";
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';

export default () => {
  return (
    <div className="radio-scale-component d-flex">
      <InputGroup size="sm" className="mr-2">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Min:</InputGroupText>
        </InputGroupAddon>
        <Input type="number" />
      </InputGroup>

      <InputGroup size="sm">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Max:</InputGroupText>
        </InputGroupAddon>
        <Input type="number" />
      </InputGroup>
    </div>
  )
}

import React from "react";
import { FreeText, RadioScale, SingleSelect, MultiSelect } from './UIComponents/AnswerTypes';

export default {
  answerTypes: [
    {
      uuid: "5678",
      label: "Free Text",
      component: <FreeText />
    },
    {
      uuid: "1234",
      label: "Radio Scale",
      component: <RadioScale />
    },
    {
      uuid: "9012",
      label: "Single Select List",
      component: <SingleSelect />
    },
    {
      uuid: "3456",
      label: "Multi Select List",
      component: <MultiSelect />
    }
  ]
}

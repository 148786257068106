import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SurveyNav from "./components/layout/navbar";
import routes from "./routes.js";
require("./icons");

const getRoutes = routes => {
  return routes.map((prop, key) => {
    return (
      <Route exact path={prop.path} component={prop.component} key={key} />
    );
  });
};

export default props => {
  return (
    <BrowserRouter>
      <SurveyNav />
      <Switch>{getRoutes(routes)}</Switch>
    </BrowserRouter>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { AuthenticateApp } from "./services/auth";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI
});

AuthenticateApp().then(() => {
  const Main = () => (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );

  ReactDOM.render(<Main />, document.getElementById("root"));
  registerServiceWorker();
});

import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Button, FormGroup, Input, Label } from "reactstrap";
import Async from "../../services/async";

export default (props) => {
  let history = useHistory();

  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    Async.get('all').then(data => setSurveys(data))
  }, []);

  const editSurvey = (uuid) => {
    history.push(`/${uuid}`);
  }

  const SurveysHeaderBar = () => (
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="m-0">Surveys</h3>
      <div>
        <Input type="select" name="select">
          <option>All Surveys</option>
          <option>Active Surveys</option>
          <option>Inactive Surveys</option>
          <option>Survey Drafts</option>
        </Input>
      </div>
    </div>
  )

  const Surveys = () => (
    <Row>
      {surveys.map((survey, index) => (
        <Col key={survey.uuid} xs="12" sm="6" md="4" xl="3">
          <Card style={{height: '20rem', cursor: 'pointer'}} onClick = {() => editSurvey(survey.uuid)}>
            <CardImg top src={survey.image_url || survey.journey.image_url} alt="..."/>
            <CardBody>
              <CardTitle>{survey.title || survey.journey.title}</CardTitle>
              <CardText>{survey.journey.description || survey.journey.title}</CardText>
            </CardBody>
            <div className="status-dot"></div>
          </Card>
        </Col>
      ))}
    </Row>
  )

  return (
    <Container>
      <SurveysHeaderBar />
      <hr/>
      <Surveys />
    </Container>
  )
}

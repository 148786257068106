import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Button, FormGroup, Input, Modal, Container, Row, Col, Card, CardBody, CardText } from "reactstrap";
import Async from "../../../services/async";
const classNames = require('classnames');

export default ({ headerText, submitText, nodeLabel, modalOpen, setModalOpen, handleSubmit }) => {
  let { surveyUuid } = useParams();

  const [nodes, setNodes] = useState([]);
  const [selected, setSelected] = useState([]);

  const selectNode = node => {
    let idx = selected.findIndex(id => id === node.uuid)

    if(idx > -1) {
      setSelected(selected.filter((n, i) => i !== idx))
    }
    else {
      setSelected([...selected, node.uuid])
    }
  }

  useEffect(() => {
    if(modalOpen && surveyUuid) {
      Async.get(`journey/${surveyUuid}/component/${nodeLabel}`)
      .then(data => {
        setNodes(data.journey.nodes)
      })
      .catch(error => {
        console.error(error)
      })
    }
  })

  const getText = (node) => {
    return (node.description || node.title || node.text)
  }

  const Options = () => {
    const cardColor = uuid => {
      return (selected.includes(uuid) ?  'dark' : 'secondary')
    }

    return (
      <Row>
        {nodes.map((node, index) => (
          <Col key={node.uuid} xs="3">
            <Card className={classNames('node', 'pointer', 'bg-secondary', {
              'bg-dark': selected.find(n => n === node.uuid)
             })}
             onClick={() => selectNode(node)}>
              <CardBody>
                <CardText className="text-light">{getText(node)}</CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <Modal isOpen={modalOpen} className="modal-lg node-select-modal">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLiveLabel">
          {headerText || 'Select Components'}
        </h5>
      </div>
      <div className="modal-body">
        <Container fluid={true}>
          <Options />
        </Container>
      </div>
      <div className="modal-footer">
        <div className="left-side">
          <Button
            className="btn-link"
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
        <div className="divider" />
        <div className="right-side">
          <Button
            className="btn-link"
            color="danger"
            type="button"
            onClick={() => handleSubmit(selected)}>
            {submitText || 'Save Selections'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

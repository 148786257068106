import React from "react";
import { FreeText, RadioScale, SingleSelect, MultiSelect } from './UIComponents/AnswerTypes';

export default {
  answerTypes: [
    {
      uuid: "freetext",
      label: "Free Text",
      component: <FreeText />
    },
    {
      uuid: "radioscale",
      label: "Radio Scale",
      component: <RadioScale />
    },
    {
      uuid: "singleselect",
      label: "Single Select List",
      component: <SingleSelect />
    },
    {
      uuid: "multiselect",
      label: "Multi Select List",
      component: <MultiSelect />
    }
  ]
}

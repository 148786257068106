import SurveysHome from "./surveys/Home";
import ManageSurvey from "./surveys/Manage";
import SurveyEditor from "./surveys/Editor";
import TakeSurvey from "./surveys/TakeSurvey";
import PublishSurvey from "./surveys/Publish";
import AnalyzeSurvey from "./surveys/Analyze";
import SurveyReport from "./surveys/Report";

const routes = [
  {
    path: "/",
    name: "Survey Manager Home",
    icon: "ni ni-bullet-list-67 text-gold",
    component: SurveysHome
  },
  {
    path: "/:surveyUuid",
    name: "Survey Manager Home",
    icon: "ni ni-bullet-list-67 text-gold",
    component: ManageSurvey
  },
  {
    path: "/editor/:surveyUuid",
    name: "Survey Editor",
    icon: "ni ni-bullet-list-67 text-gold",
    component: SurveyEditor
  },
  {
    path: "/takesurvey/:surveyUuid",
    name: "Take Survey",
    component: TakeSurvey
  },
  {
    path: "/publish/:surveyUuid",
    name: "Publish Survey",
    icon: "ni ni-bullet-list-67 text-gold",
    component: PublishSurvey
  },
  {
    path: "/analyze/:surveyUuid",
    name: "Analyze Survey Results",
    icon: "ni ni-bullet-list-67 text-gold",
    component: AnalyzeSurvey
  },
  {
    path: "/report/:surveyUuid",
    name: "Survey Report",
    icon: "ni ni-bullet-list-67 text-gold",
    component: SurveyReport
  }
];

export default routes;

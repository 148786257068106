import React, { useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Async from "../../services/async";
import config from "./config";
import arrayMove from 'array-move';
import { Question, ComponentSelectModal } from "./UIComponents";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Container, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';


export default (props) => {
  let history  = useHistory();
  let { surveyUuid } = useParams();

  const [survey,     setSurvey]     = useState({});
  const [surveyCopy, setSurveyCopy] = useState({});
  const [questions,  setQuestions]  = useState([]);
  const [modalOpen,  setModalOpen]  = useState(false);
  const [editProp,   setEditProp]   = useState(undefined);

  useEffect(() => {
    if(surveyUuid) {
      Async.get(`survey/${surveyUuid}`)
      .then(data => {
        data.questions.map((q, i) => {
          q.order = i
          if(!q.answer_type) q.answer_type = config.answerTypes[0].uuid
        })
        setSurvey(data.survey);
        setQuestions(data.questions);
      })
    }
  }, []);

  const saveSurvey = () => {
    setSurvey({ ...survey, ...surveyCopy })
  }

  const stageChanges = (prop, val) => {
    console.log('stage', prop, val)
    let obj = {}
    obj[prop] = val
    // console.log({ ...surveyCopy, ...obj })
    setSurveyCopy(obj)

    console.log(surveyCopy)
  }

  // Edit Survey Properties
  const editSurveyProperty = (prop) => setEditProp(prop)
  const clearEditProperty = () => setEditProp(undefined)

  const editQuestion = (uuid, obj) => {
    setQuestions(questions.map(question => {
      return ( question.uuid === uuid ? { ...question, ...obj } : question )
    }));
  }

  const removeQuestion = uuid => setQuestions(questions.filter(question => question.uuid !== uuid));

  const addNewQuestion = () => {
    setQuestions([
      ...questions,
      {}
    ]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let sorted = arrayMove(questions, oldIndex, newIndex)

    sorted.map((question, index) => question.order = index)

    setQuestions(sorted);
  };

  const SurveyHeaderBar = () => (
    <div className="d-flex justify-content-between align-items-center">
      {editProp === 'title' ? (
        <div style={{ maxWidth: '300px' }}>
          <InputGroup>
            <Input type="text" name="title" onChange={e => stageChanges('title', e.target.value)} placeholder="My Survey" />
            <InputGroupAddon addonType="append">
              <Button onClick={saveSurvey}>S</Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      ):(
        <h3 className="m-0">
          {survey.title || 'My Survey'}
          <Button size="sm" color="success" onClick={() => editSurveyProperty('title')}>
            E
          </Button>
        </h3>
      )}
      <div>
        <Button className="bg-dark border-dark" block size="sm">
          Add Questions
        </Button>
        <ComponentSelectModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  )

  const SortableItem = SortableElement(({ question }) => (
    <Question question={question} editQuestion={editQuestion} removeQuestion={removeQuestion} />
  ));

  const SortableList = SortableContainer(({ questions }) => {
    return (
      <ListGroup>
        {questions.map((question, index) => (
          <SortableItem key={question.uuid} index={index} question={question} />
        ))}
      </ListGroup>
    );
  });

  return questions ? (
    <Container>
      <SurveyHeaderBar />
      <hr />
      <SortableList questions={questions} onSortEnd={onSortEnd} useSortHandle />;
    </Container>
  ) : (
    <div className="text-center">Loading...</div>
  );
}

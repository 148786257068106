import axios from "axios";

const devMode = window.location.hostname === "localhost";
const apiHost = devMode
  ? "http://localhost:8000"
  : window.location.host.replace(".surveys", "");

const apiUrl = `${apiHost}/api/survey/`;

console.log("apiUrl:", apiUrl);

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    token: localStorage.getItem("token")
  }
});

export default {
  get: path => {
    return new Promise((resolve, reject) => {
      instance
        .get(path)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  },
  put: (path, body) => {
    return new Promise((resolve, reject) => {
      instance
        .put(path, body)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  },
  post: (path, body) => {
    return new Promise((resolve, reject) => {
      instance
        .post(path, body)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  },
  delete: path => {
    return new Promise((resolve, reject) => {
      instance
        .delete(path)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }
};

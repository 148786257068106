import React, { useState } from "react";
import config from "../config";
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Input, Badge, Button } from 'reactstrap';


export default ({question, editQuestion, removeQuestion}) => {
  const [editMode, setEditMode] = useState(false);

  const confirmDelete = (uuid) => {
    let c = window.confirm('Are you sure you want to remove this question from the survey?')

    if(c) removeQuestion(uuid)
  }

  return (
    <ListGroupItem className="d-flex align-items-center">
      {editMode ? (
        <Input type="textarea" defaultValue={question.description} name="text" className="question-textarea" />
      ) : (
        <div className="mr-auto">
          <ListGroupItemHeading>{question.description}</ListGroupItemHeading>
          <ListGroupItemText className={question.done ? "done" : ""}>
            <Badge color="dark" className="mr-2">order: {question.order}</Badge>{question.description}
          </ListGroupItemText>
        </div>
      )}
      <div className="answer-types">
        <select onChange={({ target }) => editQuestion(question.uuid, { answer_type: target.value })} className="form-control answer-type-select mb-3" value={question.answer_type}>
          {config.answerTypes.map(answerType => {
            return (
              <option key={answerType.uuid} value={answerType.uuid}>
                {answerType.label}
              </option>
            )
          })}
        </select>
        {config.answerTypes.find(a => a.uuid === question.answer_type).component}
      </div>
      <div className="actions">
        {editMode ? (
          <Button size="sm" color="success" onClick={() => editQuestion(question.uuid, { description: question.description })}>
            S
          </Button>
        ):(
          <Button size="sm" color="primary" onClick={() => setEditMode(true)}>
            E
          </Button>
        )}
        <br/>
        <Button size="sm" color="danger" onClick={() => confirmDelete(question.uuid)}>D</Button>
      </div>
    </ListGroupItem>
  )
}
